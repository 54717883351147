<template>
    <el-container style="height:100vh">
        <!-- <sidebar-left /> -->
        <el-container>
            <el-header height="80px" style="padding-left:0;padding-right:0;">
                <navigation />
            </el-header>
            
            <transition name="fade-transform" mode="out-in">
                <router-view :class="$router.history.current.name" />
            </transition>
        </el-container>
    </el-container>
</template>

<script>
import { 
    Navigation, 
    SidebarLeft
} from '../components';

export default {
    components: { 
        Navigation, 
        // SidebarLeft, 
    }, 
    data() {
        return {
        }
    }
}
</script>

<style lang="scss">
.el-header {
    background-color: #ffffff;
    border-bottom: 2px solid #aeaeae;
}
.el-aside {
    color: #fff;
    background-color: #2E51A3;
    margin-bottom: 0px !important;
    .el-scrollbar__wrap{
        margin-bottom: 0px; 
        overflow-x: hidden;
    }
}
.el-main{
    padding: 0px !important;
    .el-scrollbar{
        height: 100%;
    }
    .el-scrollbar__wrap{
        overflow-x: hidden;
    }
}
.scroll-main{
    max-height: calc(100vh - 80px);
}
</style>